import { Button, Grid } from "@material-ui/core"
import React from "react"
import { ArrowForwardIos } from "@material-ui/icons"
import { Link } from "gatsby"

export default function LinkButton({ text, to }: { text: string; to: string }) {
  return (
    <Grid item container justify="center" xs={12}>
      <Link to={to}>
        <Button endIcon={<ArrowForwardIos />}>{text}</Button>
      </Link>
    </Grid>
  )
}
