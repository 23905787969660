import { Box, Divider, fade, Grid, makeStyles, Typography } from '@material-ui/core'
// import { graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import HeadingBar from './HeadingBar'
import LinkButton from './LinkButton'

const useStyles = makeStyles(theme => ({
    outerBox: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[4],
        color: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
    },
    team: {
        fontWeight: theme.typography.fontWeightBold,
    },
    info: {
        lineHeight: 1.2,
    },
    place: {
        lineHeight: 1,
    },
    divider: {
        background: fade(theme.palette.common.white, 0.5),
    },
    upcoming: {
        padding: theme.spacing(1),
        background: fade(theme.palette.primary.dark, 0.7),
    },
    innerBox: {
        display: 'flex',
        // flexDirection: "column",
        alignItems: 'center',
        padding: theme.spacing(2),
        justifyContent: 'space-evenly',
    },
}))

export default function ScheduleSection() {
    const classes = useStyles()
    // const data = useStaticQuery(query);
    return (
        <>
            <HeadingBar text="Spieltage" />

            {/* <Grid item xs={12}>
        <Box className={classes.outerBox}>
          <Box className={classes.upcoming}>
            <Typography variant="caption">
              Noch 2 Tage, 21 Stunden und 48 Minuten
            </Typography>
          </Box>
          <Box className={classes.innerBox}>
            <Box
              display="flex"
              flexDirection="column"
              alignSelf="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="subtitle1" className={classes.team}>
                HERREN 1
              </Typography>
              <Typography variant="subtitle2" className={classes.info}>
                Heimspieltag
              </Typography>
              <Typography variant="subtitle2" className={classes.info}>
                DO | 11.02.2021 | 19:00 Uhr
              </Typography>
              <Typography variant="caption" className={classes.place}>
                Schäferstraße 26, Karlsruhe
              </Typography>
            </Box>
            <Divider
              variant="middle"
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Typography variant="h4">VTH</Typography>
            <Typography variant="h4">-:-</Typography>
            <Typography variant="h4">ABC</Typography>
          </Box>
        </Box>
      </Grid> */}
            <LinkButton to="/schedule" text={'ALLE SPIELTAGE'} />
        </>
    )
}

// const query = graphql`
//   query {
//     allSanityReport(limit: 3, sort: { fields: publishedAt, order: DESC }) {
//       nodes {
//         id
//         slug {
//           current
//         }
//         title
//         publishedAt(formatString: "DD. MMMM YYYY", locale: "de")
//         category {
//           title
//         }
//         mainImage {
//           ...ImageWithPreview
//         }
//       }
//     }
//   }
// `
