import React from "react"
import { Container, Grid, makeStyles } from "@material-ui/core"
import SponsorSection from "../components/Home/SponsorSection"
import TeamsSection from "../components/Home/TeamsSection"
import MainNewsSection from "../components/Home/MainNewsSection"
import NewsSection from "../components/Home/NewsSection"
import ScheduleSection from "../components/Home/ScheduleSection"
import useSEO from "../components/Shared/useSEO"
const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}))

export default function Home() {
  useSEO({ title: "Home" })
  const classes = useStyles()

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container spacing={2}>
        <MainNewsSection />
        <TeamsSection />
        <NewsSection />
        <ScheduleSection />
        <SponsorSection />
      </Grid>
    </Container>
  )
}
