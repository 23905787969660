import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { MainQuery } from '../../../graphql-types'
import ImageCard from '../Shared/ImageCard'

export default function MainNewsSection() {
    const data = useStaticQuery<MainQuery>(query)
    const news = data.allSanityReport.nodes[0] // exactly 1 news article
    return (
        <Grid item xs={12}>
            <ImageCard
                title={news.title}
                titleVariant={'h4'}
                img={news.mainImage}
                linkTo={`/news/${news.slug.current}`}
            />
        </Grid>
    )
}

const query = graphql`
    query Main {
        allSanityReport(
            limit: 1
            filter: { isHomePageArticle: { eq: true } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            nodes {
                id
                slug {
                    current
                }
                title
                publishedAt(formatString: "DD. MMMM YYYY", locale: "de")
                category {
                    title
                }
                mainImage {
                    ...ImageWithPreview
                }
            }
        }
    }
`
