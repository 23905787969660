import { Grid, useTheme } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import HeadingBar from './HeadingBar'
import ImageCard from '../Shared/ImageCard'
import { SponsorsQuery } from '../../../graphql-types'

export default function SponsorSection() {
    const theme = useTheme()
    const data = useStaticQuery<SponsorsQuery>(query)

    return (
        <>
            <HeadingBar text="Sponsoren" />
            {data.allSanitySponsor.nodes.map(sponsor => (
                <Grid key={sponsor.id} item xs={12} sm={6} md={4}>
                    <ImageCard
                        title={sponsor.season}
                        img={sponsor.image}
                        imgStyle={{
                            width: '100%',
                            height: '220px',
                            objectFit: 'contain',
                        }}
                        backgroundColor={theme.palette.common.white}
                        linkTo={sponsor.url}
                        linkIsExternal
                    />
                </Grid>
            ))}
        </>
    )
}

const query = graphql`
    query Sponsors {
        allSanitySponsor(sort: { fields: season, order: DESC }) {
            nodes {
                id
                name
                season
                url
                image {
                    ...ImageWithPreview
                }
            }
        }
    }
`
