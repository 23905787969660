import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import HeadingBar from './HeadingBar'
import LinkButton from './LinkButton'
import ImageCard from '../Shared/ImageCard'
import { LatestReportsQuery } from '../../../graphql-types'

export default function NewsSection() {
    const data = useStaticQuery<LatestReportsQuery>(query)
    return (
        <>
            <HeadingBar text="News" />
            {data.allSanityReport.nodes.map(report => {
                return (
                    <Grid key={report.id} item xs={12} sm={6} md={4}>
                        <ImageCard
                            linkTo={`/news/${report.slug.current}`}
                            img={report.mainImage}
                            category={report.category.title}
                            title={report.title}
                            date={report.publishedAt}
                        />
                    </Grid>
                )
            })}
            <LinkButton to="/news" text="WEITER ZUR NEWS ÜBERSICHT" />
        </>
    )
}

const query = graphql`
    query LatestReports {
        allSanityReport(limit: 3, sort: { fields: publishedAt, order: DESC }) {
            nodes {
                id
                slug {
                    current
                }
                title
                publishedAt(formatString: "DD. MMMM YYYY", locale: "de")
                category {
                    title
                }
                mainImage {
                    ...ImageWithPreview
                }
            }
        }
    }
`
