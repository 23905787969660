import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import HeadingBar from './HeadingBar'
import ImageCard from '../Shared/ImageCard'
import { TeamsQuery } from '../../../graphql-types'

export default function TeamsSection() {
    const data = useStaticQuery<TeamsQuery>(query)

    return (
        <>
            <HeadingBar text="Teams" />
            {data.allSanityTeam.nodes.map(team => (
                <Grid key={team.id} item xs={12} sm={6} md={4}>
                    <ImageCard
                        title={team.name}
                        img={team.image}
                        linkTo={`/teams/${team.slug.current}`}
                    />
                </Grid>
            ))}
        </>
    )
}

const query = graphql`
    query Teams {
        allSanityTeam {
            nodes {
                id
                name
                slug {
                    current
                }
                image {
                    ...ImageWithPreview
                }
            }
        }
    }
`
