import { Box, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  gridItem: {
    zIndex: 1,
    marginTop: theme.spacing(1),
    marginBottom: -theme.spacing(1),
  },
  headingBar: {
    color: theme.palette.primary.main,
  },
  headingLeft: {},
  redBar: {
    boxShadow: theme.shadows[2],
    height: 8,
    background: theme.palette.primary.main,
  },
  text: {
    textShadow: "1px 1px 2px rgba(0,0,0,0.28)",
  },
}))

export default function HeadingBar({ text }: { text: string }) {
  const classes = useStyles()
  return (
    <Grid item xs={12} className={classes.gridItem}>
      <Box display="flex" alignItems="center" className={classes.headingBar}>
        <Box mr={1.5} width={24} className={classes.redBar} />
        <Typography variant="h4" className={classes.text}>
          {text}
        </Typography>
        <Box ml={1.5} height={12} flexGrow={1} className={classes.redBar} />
      </Box>
    </Grid>
  )
}
